import { DialogUtility } from '@syncfusion/ej2-popups';
import { History } from '../authentication/_helpers/History';

let showCriticalDialog = false;
let criticalDialogShown = false;

class Stopwatch {
    constructor(sTime) {
        this.startTime = sTime;
        this.endTime = null;
        this.duration = 0;
    }

    start() {
        this.startTime = new Date();
    }

    stop() {
        if (!this.startTime) {
            this.duration = NaN;

            console.error("Unable to track http infinite loop: Invalid start time.");

            return this.duration;
        }

        this.endTime = new Date();
        this.duration = new Date(this.endTime) - new Date(this.startTime);

        return this.duration;
    }
}

class Tracker {
    constructor() {
        this.startTime = null;
        this.suspectRequests = 0;
    }
}

export class InfiniteLoopTracker {

    showCriticalInfiniteLoopMessage (){
        console.info(`${new Date()}: InfiniteLoopTracker::showCriticalInfiniteLoopMessage starts`);

        DialogUtility.alert({
            title: 'System error',
            content: "Please login and try again.\nIf the issue persist, please contact your administrator.",
            okButton: { text: 'OK' },
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' },
            isModal: true,
            close: () => {
                History.push('/authentication/login');
                showCriticalDialog = false;
            }
        });
    }

    start() {
        sessionStorage.setItem("tracker", JSON.stringify(new Tracker()));
    }

    getTrackerObj() {
        let tracker = null;

        try {
            tracker = sessionStorage.getItem("tracker") === undefined || sessionStorage.getItem("tracker") === 'undefined' ?
                null : JSON.parse(sessionStorage.getItem("tracker"));
        }
        catch (e) {
            console.error(`${new Date()}: InfiniteLoopTracker::getTrackerObj - Error parsing invalid session storage object.`, e);

            tracker = null;
        }

        if (!tracker) {
            return new Tracker();
        }

        tracker.startTime = new Date(tracker.startTime);

        return tracker;
    }

    setTrackerObj(tracker) {
        sessionStorage.setItem("tracker", JSON.stringify(tracker));
    }

    check() {
        let detected = false;

        const tracker = this.getTrackerObj();

        if (!tracker.startTime) {
            tracker.startTime = new Date();

            this.setTrackerObj(tracker);

            return false;
        }

        let sw = new Stopwatch(tracker.startTime);

        const duration = sw.stop();

        if (isNaN(duration)) {
            console.error(`${new Date()}: InfiniteLoopTracker::check -> Unable to track infinite loop: Invalid duration.`);
            return false;
        }

        if (duration < 1000) {
            tracker.suspectRequests++;

            if (tracker.suspectRequests >= 20) {
                if (tracker.alreadyDetected) {
                    if (tracker.suspectRequests % 20 === 0)
                        tracker.detectionFrequency++;
                }
                else {
                    tracker.alreadyDetected = true;
                    tracker.detectionFrequency = 1;
                    tracker.firstDtectionTime = new Date();
                }

                let frequencySW = new Stopwatch(tracker.firstDtectionTime);

                let frequencyDuration = frequencySW.stop();

                if (frequencyDuration <= 10 * 1000) {
                    if (tracker.detectionFrequency >= 5) {
                        showCriticalDialog = true;

                        delete tracker.alreadyDetected;
                        delete tracker.detectionFrequency;
                        delete tracker.firstDtectionTime;

                        if (showCriticalDialog && criticalDialogShown === false) {
                            setTimeout(() => this.showCriticalInfiniteLoopMessage(), 200);

                            criticalDialogShown = true;
                        }
                    }                    
                }
                else
                    tracker.alreadyDetected = false;

                detected = true;;
            }
        }
        else
            if (tracker.suspectRequests < 10) {
                tracker.suspectRequests = 0;
        }

        tracker.startTime = new Date();

        this.setTrackerObj(tracker);

        return detected;
    }
}