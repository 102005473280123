import React, { Suspense, lazy } from "react";
//import { createRoot } from 'react-dom/client';
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./assets/scss/style.scss";
import './assets/scss/style.css';
import "./assets/scss/syncfusion.css";
import "./assets/scss/cloud9security.css";
import Spinner from "./views/spinner/Spinner";
import { configureStore } from "./redux/Store";
import { registerLicense } from "@syncfusion/ej2-base";

// setup fake backend
//import { ConfigureFakeBackend } from "./authentication/_helpers";
// ConfigureFakeBackend();

registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY);

const App = lazy(
    () =>
        new Promise((resolve) => {
            setTimeout(() => resolve(import("./app")), 0);
        })
);

//const container = document.getElementById('root');
//const root = createRoot(container);
//root.render(
//    <Suspense fallback={<Spinner />}>
//        <Provider store={configureStore()}>
//            <App />
//        </Provider>
//    </Suspense>);

ReactDOM.render(
    <Suspense fallback={<Spinner />}>
        <Provider store={configureStore()}>
            <App />
        </Provider>
    </Suspense>,
    document.getElementById("root")
);
