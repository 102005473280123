import { ActionTypeList } from '../constants'
import { fetchFindingDetailsData } from './Action';

const initialState = {
    loading: false,
    findingsData: null,
    findingDetailsData: [],
    error: "",
};

const findingsDataPayload = (state = initialState, action) => {

    if (action.type !== ActionTypeList.FINDINGS_DATA_FETCH &&
        action.type !== ActionTypeList.FINDINGS_DATA_FETCHED &&
        action.type.includes(ActionTypeList.FINDINGS_DATA_FETCH)) {

        var status = action.type.substring(0, 4);

        if (status === 'Done') {

            if (action.payload !== undefined &&
                action.payload !== null && (
                    action.payload.hasError === undefined || action.payload.hasError === false)) {

                let finalResult = { ...state };

                if (finalResult.findingDetailsData === undefined ||
                    finalResult.findingDetailsData === null) {
                    finalResult.findingDetailsData = [];
                }

                return finalResult;
            }
        }

        return {
            ...state,
            loading: true,
            error: action.payload
        };
    }

    switch (action.type) {
        case ActionTypeList.FINDINGS_DATA_FETCHED:

            return {
                loading: false,
                findingsData: action.payload,
                findingDetailsData: [],
                error: ""
            };
        case ActionTypeList.FINDINGS_DATA_FETCH:

            return {
                loading: true,
                findingsData: null,
                findingDetailsData: [],
                error: action.payload
            };
        default:
            return state;
    }
}

export default findingsDataPayload;