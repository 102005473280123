import { ActionTypeList } from '../../constants/index'

const initialState = {
    loading: false,
    scanCancelData: null,
    error: "",
};

const scanCancelPayload = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypeList.SCAN_CANCELED:
            //console.log(`${new Date()}: scanCancelPayload instantiated with URL: ${recentScanApi}`);
            return {
                loading: false,
                scanCancelData: action.payload,
                error: ""
            };
        case ActionTypeList.SCAN_CANCEL:
            return {
                loading: true,
                scanCancelData: action.payload,
                error: action.payload
            };
        case ActionTypeList.SCAN_CANCEL_DATA_RESET:
            return { ...initialState };
        default:
            return state;
    }
}

export default scanCancelPayload;