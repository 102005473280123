import { BehaviorSubject } from "rxjs";
import { handleJsonResponse, handleResponse, handleJsonResponseWithDefaultErrorMessage } from "../_helpers/index";
import { getApiRoot } from '../../helpers'
import axios from 'axios';
import { LOGGED_IN } from '../../redux/constants';
import Cookies from 'js-cookie';

const currentAxios = axios.create({ baseURL: getBaseUrl() })

const currentUserSubject = new BehaviorSubject(
    sessionStorage.getItem("currentUser") === undefined || sessionStorage.getItem("currentUser") === 'undefined' ?
        {} : JSON.parse(sessionStorage.getItem("currentUser"))
);

let saveToReduxStore_ = (type, payload) => {
}

export const AuthenticationService = {
    set saveToReduxStore(fct) {
        saveToReduxStore_ = fct;
    },
    login,
    realLogin,
    logout,
    requestPasswordReset,
    resetPassword,
    activateAccount,
    changePassword,
    adminResetPassword,
    unlock,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {
        return currentUserSubject.value;
    },
};

function getBaseUrl() {

    //console.log(`${new Date()}: process.env.REACT_APP_GATEWAY_API_URL ${process.env.REACT_APP_GATEWAY_API_URL}`);
    //console.log(`${new Date()}: process.env.REACT_APP_LOCAL_UI_API_URL ${process.env.REACT_APP_LOCAL_UI_API_URL}`);

    return getApiRoot();
}

function login(username, password) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password }),
    };

    return fetch(`/users/authenticate`, requestOptions)
        .then(handleResponse)
        .then((user) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            sessionStorage.setItem("currentUser", JSON.stringify(user));
            currentUserSubject.next(user);         

            return user;
        });
}

const handleLocalHostAuth = (tokenId) => {
    let hostname = window && window.location && window.location.hostname;

    if (!hostname.includes("localhost"))
        return;

    Cookies.set('TokenId', `${tokenId}`, { expires: 1, path: '/', domain: 'localpc.com:8787' });
}

async function realLogin(username, password) {
    const body = { username, password };

    return await currentAxios.post(`/security/basic-authenticate`, body)
        .then(response => {
            return handleJsonResponseWithDefaultErrorMessage(response, "User name/password provided is not valid.");
        })
        .then((user) => {
            handleLocalHostAuth(user.data?.tokenId || user.tokenId);

            sessionStorage.setItem("currentUser", JSON.stringify(user.data || user));
            currentUserSubject.next(user.data || user);

            saveToReduxStore_(LOGGED_IN, true);

            return user;
        });
}

async function unlock(accountId, userId, password) {

    const body = { password };

    return await currentAxios.post(`/accounts/${accountId}/users/${userId}/password-validation`, body)
        .then(handleJsonResponse);
}

async function requestPasswordReset(userEmail) {
    const body = { userEmail: userEmail };

    return await currentAxios.post(`/password-reset`, body)
        .then(handleJsonResponse);
}

async function resetPassword(id, newPassword) {
    const body = { newPassword };

    return await currentAxios.put(`/password-reset/${id}`, body)
        .then(handleJsonResponse);
}

async function activateAccount(id, newPassword) {

    const body = { newPassword };

    return await currentAxios.put(`/member-activation/${id}`, body)
        .then(handleJsonResponse);
}

async function changePassword(accountId, userId, currentPassword, newPassword) {

    const body = { currentPassword, newPassword };

    return await currentAxios.put(`/accounts/${accountId}/users/${userId}/password`, body)
        .then(handleJsonResponse);
}

async function adminResetPassword(accountId, userId, newPassword) {

    const body = { newPassword };

    return await currentAxios.put(`/accounts/${accountId}/users/${userId}/password`, body)
        .then(handleJsonResponse);
}

function logout() {
    console.log(`${new Date()}: AuthenticationService::logout starts from ${(new Error()).stack} removing session storage currentUser`);

    // remove user from local storage to log user out
    sessionStorage.removeItem("currentUser");
    currentUserSubject.next(undefined);

    saveToReduxStore_(LOGGED_IN, false);
}

