import { ActionTypeList } from '../../constants'

const initialState = {
    loading: false,
    targetSettings: null,
    error: "",
};

export const targetSettingsPayload = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypeList.TARGET_SETTING_LIST_FETCHED:
            //console.log(`${new Date()}: accountTargetSettingsPayload action ${action.type} targetsettings ${JSON.stringify(action.payload)}`);
            return {
                loading: false,
                targetSettings: action.payload,
                error: ""
            };
        case ActionTypeList.TARGET_SETTING_FETCH_LIST:
            console.log(`${new Date()}: targetSettingsPayload action ${action.type} targetsettings []`);
            return {
                loading: true,
                targetSettings: [],
                error: action.payload
            };
        default:
            return state;
    }
}

export const accountTargetSettingsPayload = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypeList.ACCOUNT_TARGET_SETTING_LIST_FETCHED:

            //console.log(`${new Date()}: accountTargetSettingsPayload action ${action.type} targetsettings ${JSON.stringify(result)}`);

            return {
                loading: false,
                targetSettings: action.payload,
                error: ""
            };
        case ActionTypeList.ACCOUNT_TARGET_SETTING_FETCH_LIST:
            console.log(`${new Date()}: accountTargetSettingsPayload action ${action.type} targetsettings []`);

            return {
                loading: true,
                targetSettings: [],
                error: action.payload
            };
        default:
            return state;
    }
}

